/// Utility classes
.u-fw_900 {
    font-weight: 900;
}
.u-fw_600 {
    font-weight: 600;
}
.u-fw_200 {
    font-weight: 200;
}

.u_disabled {
    pointer-events: none;
    opacity: 0.5;
}

.u-text_lowest {
    color: rgba(black, 0.55);
}

.u-text_low {
    color: rgba(black, 0.65);
}

.u-font-size_20 {
    font-size: 20px;
}
.u-font-size_19 {
    font-size: 19px;
}
.u-font-size_18 {
    font-size: 18px;
}
.u-font-size_17 {
    font-size: 17px;
}
.u-font-size_16 {
    font-size: 16px;
}
.u-font-size_15 {
    font-size: 15px;
}
.u-font-size_14 {
    font-size: 14px;
}
.u-font-size_13 {
    font-size: 13px;
}
.u-font-size_12 {
    font-size: 12px;
}
.u-font-size_11 {
    font-size: 11px;
}
.u-font-size_10 {
    font-size: 10px;
}

.u-p_fixed {
    position: fixed;
}
.u-p_absolute {
    position: absolute;
}
.u-p_relative {
    position: relative;
}

.u-bold {
    font-weight: bold;
}

.u-italic {
    font-style: italic;
}

.u-align-items_center {
    align-items: center;
}

.u-pe_none {
    pointer-events: none;
}

.u-visibility_hidden {
    visibility: hidden;
}

.u-d_none {
    display: none;
}

.u-d_flex {
    display: flex;
}

.u-d_table {
    display: table;
}

.u-d_table-cell {
    display: table-cell;
}

.u-d_table-row {
    display: table-row;
}

.u-d_block {
    display: block;
}

.u-fw_wrap {
    flex-wrap: wrap;
}

.u-jc_flex-end {
    justify-content: flex-end;
}

.u-jc_flex-start {
    justify-content: flex-start;
}

.u-jc_center {
    justify-content: center;
}

.u-pad_0 {
    padding: 0px;
}
.u-pad_1 {
    padding: 8px;
}
.u-pad_1-5 {
    padding: 12px;
}
.u-pad_2 {
    padding: 16px;
}
.u-pad_3 {
    padding: 24px;
}
.u-pad_4 {
    padding: 32px;
}
.u-pad_5 {
    padding: 40px;
}
.u-pad_6 {
    padding: 48px;
}

.u-pad-r_0 {
    padding-right: 0px;
}

.u-pad-r_1 {
    padding-right: 8px;
}

.u-pad-r_2 {
    padding-right: 16px;
}

.u-pad-l_0 {
    padding-left: 8px * 0;
}
.u-pad-l_1 {
    padding-left: 8px * 1;
}
.u-pad-l_2 {
    padding-left: 8px * 2;
}
.u-pad-l_3 {
    padding-left: 8px * 3;
}
.u-pad-l_4 {
    padding-left: 8px * 4;
}
.u-pad-l_5 {
    padding-left: 8px * 5;
}
.u-pad-l_6 {
    padding-left: 8px * 6;
}
.u-pad-l_7 {
    padding-left: 8px * 7;
}
.u-pad-l_8 {
    padding-left: 8px * 8;
}
.u-pad-l_9 {
    padding-left: 8px * 9;
}
.u-pad-l_10 {
    padding-left: 8px * 10;
}
.u-pad-l_11 {
    padding-left: 8px * 11;
}
.u-pad-l_12 {
    padding-left: 8px * 12;
}
.u-pad-l_13 {
    padding-left: 8px * 13;
}
.u-pad-l_14 {
    padding-left: 8px * 14;
}

.u-pad-b_0 {
    padding-bottom: 0px;
}
.u-pad-b_1 {
    padding-bottom: 8px;
}

.u-pad-b_10 {
    padding-bottom: 80px;
}

.u-pad-t_0 {
    padding-top: 8px * 0;
}
.u-pad-t_1 {
    padding-top: 8px * 1;
}
.u-pad-t_2 {
    padding-top: 8px * 2;
}
.u-pad-t_3 {
    padding-top: 8px * 3;
}
.u-pad-t_4 {
    padding-top: 8px * 4;
}
.u-pad-t_5 {
    padding-top: 8px * 5;
}
.u-pad-t_6 {
    padding-top: 8px * 6;
}
.u-pad-t_7 {
    padding-top: 8px * 7;
}
.u-pad-t_8 {
    padding-top: 8px * 8;
}

.u-mar_0 {
    margin: 0px;
}
.u-mar_1 {
    margin: 8px;
}
.u-mar_2 {
    margin: 16px;
}
.u-mar_3 {
    margin: 24px;
}
.u-mar_4 {
    margin: 32px;
}

.u-mar-lr_auto {
    margin-left: auto;
    margin-right: auto;
}
.u-mar-lr_1 {
    margin-left: 8px * 1;
    margin-right: 8px * 1;
}
.u-mar-lr_2 {
    margin-left: 8px * 2;
    margin-right: 8px * 2;
}

.u-mar-l_auto {
    margin-left: auto;
}

.u-mar-l_1 {
    margin-left: 8px * 1;
}

.u-mar-l_2 {
    margin-left: 8px * 2;
}
.u-mar-l_3 {
    margin-left: 8px * 3;
}
.u-mar-l_4 {
    margin-left: 8px * 4;
}

.u-mar-r_auto {
    margin-right: auto;
}
.u-mar-r_1 {
    margin-right: 8px;
}
.u-mar-r_2 {
    margin-right: 16px;
}
.u-mar-r_3 {
    margin-right: 24px;
}

.u-mar-b_0 {
    margin-bottom: 0px;
}
.u-mar-b_0 {
    margin-bottom: 0px;
}

.u-mar-b_point5 {
    margin-bottom: 4px;
}

.u-mar-t_auto {
    margin-top: auto;
}
.u-mar-t_0 {
    margin-top: 8px * 0;
}
.u-mar-t_1 {
    margin-top: 8px * 1;
}
.u-mar-t_2 {
    margin-top: 8px * 2;
}
.u-mar-t_3 {
    margin-top: 8px * 3;
}
.u-mar-t_4 {
    margin-top: 8px * 4;
}
.u-mar-t_5 {
    margin-top: 8px * 5;
}
.u-mar-t_6 {
    margin-top: 8px * 6;
}
.u-mar-t_7 {
    margin-top: 8px * 7;
}

.u-mar-t_8 {
    margin-top: 8px * 8;
}
.u-mar-t_9 {
    margin-top: 8px * 9;
}
.u-mar-t_10 {
    margin-top: 8px * 10;
}
.u-mar-t_11 {
    margin-top: 8px * 11;
}
.u-mar-t_12 {
    margin-top: 8px * 12;
}

.u-mar-tb_1 {
    margin-top: 8px * 1;
    margin-bottom: 8px * 1;
}

.u-mar-tb_2 {
    margin-top: 8px * 2;
    margin-bottom: 8px * 2;
}

.u-mar-b_1 {
    margin-bottom: 8px;
}

.u-mar-b_2 {
    margin-bottom: 16px;
}

.u-mar-b_3 {
    margin-bottom: 24px;
}

.u-mar-b_4 {
    margin-bottom: 32px;
}

.u-mar-b_5 {
    margin-bottom: 40px;
}

.u-mar-b_6 {
    margin-bottom: 48px;
}

.u-mar-b_7 {
    margin-bottom: 56px;
}

.u-mar-b_8 {
    margin-bottom: 64px;
}

.u-ta_center {
    text-align: center;
}
.u-ta_right {
    text-align: right;
}

.u-text_capitalize {
    text-transform: capitalize;
}

.u-text_uppercase {
    text-transform: uppercase;
}

.u-w_full {
    width: 100%;
}
.u-w_25perc {
    width: 25%;
}
.u-w_33perc {
    width: 33.333%;
}
.u-w_1ch {
    width: 1ch;
}

.u-flex_1 {
    flex: 1;
}

.u-flex-wrap_wrap {
    flex-wrap: wrap;
}

.u-fd_column {
    flex-direction: column;
}

.u-ai_center {
    align-items: center;
}

.u-ai_flex-end {
    align-items: flex-end;
}

.u-ai_flex-start {
    align-items: flex-start;
}

.u-o_75 {
    opacity: 0.75;
}
.u-o_50 {
    opacity: 0.5;
}
.u-o_35 {
    opacity: 0.35;
}
.u-o_25 {
    opacity: 0.25;
}
.u-o_0 {
    opacity: 0;
}

.u-remove-link-style {
    text-decoration: none;
    color: inherit;
}

/// Color
.u-c_brand {
    color: var(--brand);
}

.u-c_white {
    color: white;
}

.u-c_danger {
    color: var(--color_danger);
}

/// Header classes
.header_l {
    font-weight: 900;
    font-size: 32px;
}
.header_m {
    font-weight: 900;
    font-size: 24px;
}

/// Other
.u-cursor_pointer {
    cursor: pointer;
}
